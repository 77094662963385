@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #a79277;
  scroll-behavior: smooth;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

/* ######### INFO PAGE ######### */

.info-main-box {
  @apply w-full lg:px-8 px-4 my-10;
}

.info-box {
  @apply flex justify-between bg-white/30 lg:p-8 p-4 rounded max-lg:flex-col gap-4;
}

.content-box {
  @apply lg:w-5/12 w-full;
}

.content-h1 {
  @apply lg:text-4xl text-2xl font-bold mt-2 text-center italic;
}

.content-text {
  @apply lg:text-xl text-base my-2;
}

.content-img-box {
  @apply max-lg:flex max-lg:justify-center max-lg:items-center;
}

.content-img {
  @apply w-[640px] h-[480px] object-cover;
}

/* ######### SPECIAL NEW PAGE ######### */


.special-box {
  @apply w-full lg:p-4 md:p-2 p-0;
}

.special-header {
  @apply lg:text-2xl text-lg font-bold;
}  

.special-content {
  @apply lg:text-lg text-sm leading-loose lg:py-2 lg:px-4 py-1 px-2 my-2 ;
}  

